
import { DateTime } from "luxon";
import { Component, Vue, Watch } from "vue-property-decorator";
import CalendarIcon from '@/assets/calendar.svg';
import FilterIcon from '@/assets/icon_filter.svg';
import TrashIcon from '@/assets/icon_trash.svg';
import MapFilter from "@/components/MapFilter.vue";
import { PAGINATION_LIMIT } from "@/main";
import * as Bridge from "@/services/Bridge";
import { RawLocation } from "vue-router";

@Component({
    components: {
        CalendarIcon,
        FilterIcon,
        MapFilter,
        TrashIcon
    }
})
export default class Bookings extends Vue {
    public bookingDetailsTranslationMap: Array<{ key: keyof DeskBookingResponse; i18n: string }> = [
        { key: 'floor', i18n: 'Level' },
        { key: 'room', i18n: 'Room' },
        { key: 'bench', i18n: 'Bench' },
        { key: 'desk', i18n: 'Desk' }
    ];
    public bookingTypeViewMode: 'desk' | 'parking' = 'desk';
    public count = 0;
    public currentPage = 1;
    public deskBookings: Array<DeskBookingResponse> = [];
    public floors = [
        { value: null, text: this.$t('Level') },
        { value: -1, text: this.$t('Level') + ' -1' },
        { value: 1, text: this.$t('Level') + ' 1' },
        { value: 2, text: this.$t('Level') + ' 2' },
        { value: 3, text: this.$t('Level') + ' 3' },
        { value: 4, text: this.$t('Level') + ' 4' },
    ];
    public isDateRangeFilterEnable = true;
    public parkingBookings: Array<ParkingBookingResponse> = [];
    public selectedBooking: DeskBookingResponse | ParkingBookingResponse | null = null;
    public selectedDateRange = {
        start: DateTime.now().startOf('day').toJSDate(),
        end: DateTime.now().startOf('day').plus({ months: 1 }).toJSDate()
    }
    public selectedFloor: number | null = null;
    public today = DateTime.now().startOf('day');
    public userSearchQuery = '';

    public get isAdmin(): boolean {
        return this.$root.$data.isAdminMode && Bridge.user.isAdmin;
    }

    public get notFoundImage() {
        const filename = this.isAdmin ? 'not_found_dark' : 'not_found';

        return require(`@/assets/${filename}.png`);
    }

    public get paginationLimit() {
        return PAGINATION_LIMIT;
    }

    public async created() {
        await this.setDeskBookingCount();
        await this.setDeskBookings(1);
    }

    public formatDateToFrLocale(beginDate: DateTime) {
        return beginDate.setLocale('fr').toFormat('cccc dd LLL');
    }

    public formatDateToFrLocaleShort(date: DateTime) {
        return date.setLocale('fr').toFormat('dd LLL');
    }

    public openUnbookingModal(booking: DeskBookingResponse | ParkingBookingResponse) {
        this.selectedBooking = booking;
        this.$bvModal.show('delete-reservation-modal');
    }

    public async setDeskBookings(page: number) {
        const payload = this.payload;
        const floorFilter = this.selectedFloor ?? undefined;
        const paginate: PaginationPayload = { paginate: true, limit: PAGINATION_LIMIT, offset: PAGINATION_LIMIT * (page - 1) };

        this.deskBookings = await Bridge.getDeskBookings(floorFilter, payload.dateFilter, payload.userFilter, payload.isLimitedToCurrentUser, paginate);
    }

    public async setDeskBookingCount() {
        const payload = this.payload;
        const floorFilter = this.selectedFloor ?? undefined;

        this.count = await Bridge.getDeskBookingCount(floorFilter, payload.dateFilter, payload.userFilter, payload.isLimitedToCurrentUser);
    }

    public async setParkingSpaceBookings(page: number) {
        const payload = this.payload;
        const paginate: PaginationPayload = { paginate: true, limit: PAGINATION_LIMIT, offset: PAGINATION_LIMIT * (page - 1) };

        this.parkingBookings = await Bridge.getParkingSpaceBookings(payload.dateFilter, payload.userFilter, payload.isLimitedToCurrentUser, paginate);
    }

    public async setParkingSpaceBookingCount() {
        const payload = this.payload;

        this.count = await Bridge.getParkingSpaceBookingCount(payload.dateFilter, payload.userFilter, payload.isLimitedToCurrentUser);
    }

    public async unbook(booking: DeskBookingResponse | ParkingBookingResponse | null) {
        if (booking === null) {
            return;
        }
        if (this.isBookingResponse(booking)) {
            const isSuccessful = await Bridge.unbookDesk(booking.beginDate, booking.endDate, booking.login);

            if (isSuccessful) {
                this.deskBookings.splice(this.deskBookings.indexOf(booking), 1);
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
                this.selectedBooking = null;
            }

        }
        if (this.isParkingBookingResponse(booking)) {
            const isSuccessful = await Bridge.unbookParkingSpace(booking.beginDate, booking.endDate, booking.login);

            if (isSuccessful) {
                this.parkingBookings.splice(this.parkingBookings.indexOf(booking), 1);
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
                this.selectedBooking = null;
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string);
                this.selectedBooking = null;
            }
        }
    }

    private get payload(): FiltersPayload {
        const beginDate = DateTime.fromJSDate(this.selectedDateRange.start).startOf('day');
        const endDate = DateTime.fromJSDate(this.selectedDateRange.end).startOf('day');
        const dateFilter: DateFilter | undefined = this.isDateRangeFilterEnable ? { beginDate: beginDate, endDate: endDate } : undefined;
        const userFilter = this.userSearchQuery === '' ? undefined : this.userSearchQuery;
        const isLimitedToCurrentUser = !(this.isAdmin);

        return { dateFilter, userFilter, isLimitedToCurrentUser };
    }

    private isBookingResponse(booking: DeskBookingResponse | ParkingBookingResponse): booking is DeskBookingResponse {
        return (booking as DeskBookingResponse).desk !== undefined;
    }

    private isParkingBookingResponse(booking: DeskBookingResponse | ParkingBookingResponse): booking is ParkingBookingResponse {
        return (booking as ParkingBookingResponse).parking !== undefined;
    }

    private async loadData() {
        if (this.bookingTypeViewMode === 'desk') {
            await this.setDeskBookingCount();
            await this.setDeskBookings(this.currentPage);
        }
        if (this.bookingTypeViewMode === 'parking') {
            await this.setParkingSpaceBookingCount();
            await this.setParkingSpaceBookings(this.currentPage);
        }
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000
        });
    }

    @Watch('bookingTypeViewMode')
    async onBookingTypeViewModeChange() {
        this.currentPage = 1;
        await this.loadData();
    }

    @Watch('currentPage')
    async onCurrentPageChange() {
        await this.loadData();
    }

    @Watch('isAdmin')
    async onIsAdminChange() {
        this.currentPage = 1;
        await this.loadData();
    }

    @Watch('isDateRangeFilterEnable')
    async onIsDateRangeFilterEnable() {
        this.currentPage = 1;
        await this.loadData();

        if (!this.isDateRangeFilterEnable && this.bookingPeriod === 'past') {
            if (this.bookingTypeViewMode === 'desk') {
                this.deskBookings = this.deskBookings.filter(booking => booking.endDate < this.today);
            }
            if (this.bookingTypeViewMode === 'parking') {
                this.parkingBookings = this.parkingBookings.filter(booking => booking.endDate < this.today);
            }
        }
    }

    @Watch('selectedDateRange')
    async onSelectedDateRange() {
        this.currentPage = 1;
        await this.loadData();
    }

    @Watch('selectedFloor')
    async onSelectedFloor() {
        this.currentPage = 1;
        await this.loadData();
    }

    @Watch('userSearchQuery')
    async onUserSearchQuery() {
        this.currentPage = 1;
        await this.loadData();
    }

    /* MOBILE ONLY LOGIC */

    public bookingPeriod: 'upcoming' | 'past' = 'upcoming';

    public toggleBookingPeriod(bookingPeriod: 'upcoming' | 'past') {
        this.bookingPeriod = bookingPeriod;
    }

    public viewPlan(type: 'desk' | 'parking') {
        const location: RawLocation = {
            path: `/${type === 'desk' ? 'book' : 'parking'}`,
            query: type === 'desk' ? { pan: 'book' } : {}
        }
        this.$router.push(location);
    }

    @Watch('bookingPeriod')
    onBookingPeriodChange() {
        if (this.bookingPeriod === 'past') {
            this.isDateRangeFilterEnable = false;
        }
        if (this.bookingPeriod === 'upcoming') {
            this.isDateRangeFilterEnable = true;
        }
    }

    /* ***************** */
}
