
import { DateTime } from "luxon";
import { Component, Vue, Watch } from "vue-property-decorator";

import CalendarIcon from "@/assets/calendar.svg"
import ConferenceRoomTimeLine from "@/components/ConferenceRoom/ConferenceRoomTimeLine.vue"
import ConferenceRoomTimeSlot from "@/components/ConferenceRoom/ConferenceRoomTimeSlot.vue"
import ConferenceRoomSchedule from "@/components/ConferenceRoom/ConferenceRoomSchedule.vue";
import ConferenceRoomTimeSlotBookingForm from "@/components/ConferenceRoom/ConferenceRoomTimeSlotBookingForm.vue";
import { getConferenceRooms, getDailySchedules } from "@/services/ConferenceRoom";
import { getMsalInstance } from "@/services/Msal";

@Component({
    components: {
        CalendarIcon,
        ConferenceRoomTimeLine,
        ConferenceRoomTimeSlot,
        ConferenceRoomSchedule,
        ConferenceRoomTimeSlotBookingForm
    },
})
export default class ConferenceRoomView extends Vue {
    public availabilityViews: Array<Array<string>> = [];
    public conferenceRooms: Array<ConferenceRoom> = [];
    public selectedDate = DateTime.now().toISO();
    public minDate = DateTime.now().toISO();
    public modelConfig = { type: 'string' };
    public defaultSlot!: DateTime;

    // used to re-render schedule after room booking
    public scheduleKey = 0;

    public selectedRoomIndex = 0;

    public created() {
        const selectedDate = DateTime.fromISO(this.selectedDate);
        const now = DateTime.now();

        if (selectedDate.hasSame(now, 'day')) {
            this.defaultSlot = now.plus({ hour: 1 }).set({ minute: 0, second: 0, millisecond: 0 });
        } else {
            this.defaultSlot = selectedDate.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        }
    }

    private getSlotFromTime(time: DateTime) {
        const hour = time.hour;
        const minute = time.minute;

        return ((hour * 4) + Math.ceil(minute / 15)) - 32;
    }

    private async getAvailabilityViews() {
        const availabilityViews: Array<Array<string>> = [];
        const date = DateTime.fromISO(this.selectedDate);
        const schedules = await getDailySchedules(date.set({ 'hour': 8, 'minute': 0, 'second': 0, 'millisecond': 0 }), this.conferenceRooms.map(e => e.address));

        for (const schedule of schedules) {
            const availabilityView = new Array<string>(48).fill('');

            if (schedule.scheduleItems !== null && typeof schedule.scheduleItems !== 'undefined') {
                for (const item of schedule.scheduleItems) {
                    if (
                        ((item.start !== null && typeof item.start !== 'undefined') && typeof item.start.dateTime !== 'undefined') &&
                        ((item.end !== null && typeof item.end !== 'undefined') && typeof item.end.dateTime !== 'undefined') &&
                        (item.subject !== null && typeof item.subject !== 'undefined')
                    ) {
                        const startingSlot = this.getSlotFromTime(DateTime.fromISO(item.start.dateTime));
                        const endingSlot = this.getSlotFromTime(DateTime.fromISO(item.end.dateTime));
                        const subjects = new Array(endingSlot - startingSlot).fill(item.subject.trimEnd());

                        availabilityView.splice(startingSlot, subjects.length, ...subjects);
                    }
                }
            }
            availabilityViews.push(availabilityView);
        }
        return availabilityViews;
    }

    public async setAvailavilityViews() {
        this.availabilityViews = await this.getAvailabilityViews();
    }

    public async login() {
        await getMsalInstance()?.login();
        await this.setAvailavilityViews();
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000
        });
    }

    public refreshAfterBooking(status: 'Confirmed' | 'Failure') {
        this.showToast(this.$t(status) as string, this.$t(status + '_room_booking') as string)

        if (status === 'Confirmed') {
            setTimeout(async () => {
                await this.setAvailavilityViews();
                this.scheduleKey += 1;
            }, 6000);
        }
    }

    get isAuthenticated() {
        return getMsalInstance()?.isUserAuthenticated ?? false;
    }

    public formatDate(date: string) {
        return DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATE_HUGE)
    }

    async mounted() {
        this.conferenceRooms = getConferenceRooms();
        await this.setAvailavilityViews();
    }

    @Watch('selectedDate')
    async onSelectedDateChange() {
        await this.setAvailavilityViews();
    }
}
