import Vue from "vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './assets/styles/main.scss';
import App from "./App.vue";
import router from './router';
import VCalendar from 'v-calendar';

import i18n from './i18n';
import { getEnvironmentConfig, loadEnvironment } from './config/Environment';
import { MsalConfig } from './config/MsalConfig';
import { setMsalInstance } from './services/Msal';

export const PAGINATION_LIMIT = 50;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VCalendar);
Vue.config.productionTip = false;

const store = {
    state: {
        isAdminMode: false
    }
};

loadEnvironment().then(() => {
    const environmentConfig = getEnvironmentConfig();
    const msalConfig = new MsalConfig(environmentConfig.msal.clientId, environmentConfig.msal.tenantId);

    setMsalInstance(msalConfig);
    new Vue({
        render: h => h(App),
        router,
        i18n,
        data: store.state
    })
    .$mount("#app");
});
