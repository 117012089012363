
import { DateTime } from 'luxon';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import ArrowIcon from '@/assets/arrow.svg';
import deskCoordinatesJSON from '@/assets/configuration/desk_coordinates.json';
import ConferenceRoomDesk from '@/components/ConferenceRoom/ConferenceRoomDesk.vue';
import * as Bridge from '@/services/Bridge';
import { getConferenceRooms } from '@/services/ConferenceRoom';
import { getDiffInDay } from '@/services/Date';

@Component({
    components: {
        ArrowIcon,
        ConferenceRoomDesk
    }
})
export default class FloorMap extends Vue {
    @Prop() readonly _bookingToHighlight!: DeskBookingResponse | null;
    @Prop() readonly _dateRange!: DateRangeString;
    @Prop() readonly _duration!: BookingDuration;
    @Prop() readonly _floor!: number;

    public bookings: Array<DeskBookingResponse> = [];
    public dateRange = {
        start: DateTime.fromISO(this._dateRange.start).startOf('day'),
        end: DateTime.fromISO(this._dateRange.end).startOf('day')
    };
    public floorBookings: Array<DeskBookingResponse> = [];
    public lockedDesks: Array<LockedDeskResponses> = [];
    public selectedDeskId = '';

    public get benchSpacing() {
        return 2;
    }

    public get conferenceRooms() {
        return getConferenceRooms(this._floor);
    }

    public get coordinates() {
        return deskCoordinatesJSON as Array<DeskCoordinates>;
    }

    public get floors() {
        return [4, 3, 2, 1, 0, -1];
    }

    public get isAdmin() {
        return Bridge.user.isAdmin && this.$root.$data.isAdminMode;
    }

    public get isStartAndEndDateEqual() {
        return getDiffInDay(this.dateRange.start, this.dateRange.end) === 0;
    }

    public get bookingModalI18nPath() {
        if (this._duration === 'day' && this.isStartAndEndDateEqual) {
            return 'Reservation_info_one_day';
        }
        if (this._duration === 'morning') {
            return 'Reservation_info_morning';
        }
        if (this._duration === 'afternoon') {
            return 'Reservation_info_afternoon';
        }
        return 'Reservation_info';
    }

    public async book() {
        if (this._duration === 'day' && this.isStartAndEndDateEqual) {
            const isSuccessful = await Bridge.bookDesk(this.dateRange.start, this.dateRange.end, this._duration, this.selectedDeskId);

            if (isSuccessful) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
            }
        } else {
            const bookingPromises: Array<Promise<boolean>> = [];

            for (let d = this.dateRange.start; d <= this.dateRange.end; d = d.plus({ day: 1 })) {
                bookingPromises.push(Bridge.bookDesk(d, d, this._duration, this.selectedDeskId));
            }

            const isSuccessful = await Promise.all(bookingPromises);

            if (isSuccessful.every(e => e === true)) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
            }
        }
        await this.setDeskBookings();
    }

    public get conflictingBooking(): DeskBookingResponse | Array<DeskBookingResponse> | null {
        const bookings = this.bookings.filter(e => e.login === this.userLogin);

        if (bookings.length === 0) {
            return null;
        }
        if (bookings.length === 1 && this.isStartAndEndDateEqual) {
            return bookings[0];
        }
        return bookings;
    }

    public async created() {
        await this.setDeskBookings();
        await this.setLockedDesk();
    }

    public formatDateToFrLocale(beginDate: DateTime, duration: string) {
        return beginDate.setLocale('fr').toFormat(`DDDD ' - ${this.$t(duration)}'`);
    }

    public formatDateToFrLocaleShort(date: DateTime) {
        return date.setLocale('fr').toFormat('dd LLL');
    }

    public getDeskBookings(id: string) {
        return this.floorBookings.filter(e => e.id === id);
    }

    public getFloorRooms(floorNumber: number) {
        const desks = this.coordinates.find(floor => floor.floor === floorNumber);

        if (typeof desks === 'undefined') {
            return [];
        }
        return desks.rooms;
    }

    public getStyle(id: string) {
        const booking = this.floorBookings.find(e => e.id === id);

        if (typeof booking === 'undefined') {
            return '';
        }
        return booking.login === this.userLogin ? 'booked-by-user' : 'booked';
    }

    public isBooked(id: string) {
        return this.floorBookings.some(e => e.id === id);
    }

    public isLocked(id: string) {
        return this.lockedDesks.some(e => e.id === id);
    }

    public async lock() {
        if (this.isStartAndEndDateEqual) {
            const isSuccessful = await Bridge.lockDesk(this.dateRange.start, this.selectedDeskId);

            if (isSuccessful) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Locking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Locking_failure') as string);
            }
        } else {
            const bookingPromises: Array<Promise<boolean>> = [];

            for (let d = this.dateRange.start; d <= this.dateRange.end; d = d.plus({ day: 1 })) {
                bookingPromises.push(Bridge.lockDesk(d, this.selectedDeskId));
            }

            const isSuccessful = await Promise.all(bookingPromises);

            if (isSuccessful.every(e => e === true)) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Locking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Locking_failure') as string);
            }
        }
        await this.setLockedDesk();
    }

    public openModal(id: string) {
        this.selectedDeskId = id;
        if (this.isSelectedDeskBookable) {
            if (this.isAdmin) {
                if (this.isLocked(id)) {
                    this.$bvModal.show('unlock-desk-modal')
                } else {
                    this.$bvModal.show('lock-desk-modal')
                }
            }
            else {
                if (!this.isLocked(id)) {
                    if (this.conflictingBooking === null) {
                        this.$bvModal.show('new-reservation-modal');
                    } else {
                        this.$bvModal.show('replace-reservation-modal');
                    }
                }
            }
        }
    }

    public async rebook() {
        await this.unbook();
        await this.book();
    }

    public triggerTransition(id: string) {
        if (this._bookingToHighlight === null || this._bookingToHighlight.id !== id) {
            return id;
        }
        if (this._bookingToHighlight.id === id) {
            return 'highlight';
        }
    }

    public async unlock() {
        if (this.isStartAndEndDateEqual) {
            const isSuccessful = await Bridge.unlockDesk(this.dateRange.start, this.selectedDeskId);

            if (isSuccessful) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Unlocking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Unlocking_failure') as string);
            }
        } else {
            const bookingPromises: Array<Promise<boolean>> = [];

            for (let d = this.dateRange.start; d <= this.dateRange.end; d = d.plus({ day: 1 })) {
                bookingPromises.push(Bridge.unlockDesk(d, this.selectedDeskId));
            }

            const isSuccessful = await Promise.all(bookingPromises);

            if (isSuccessful.every(e => e === true)) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Unlocking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Unlocking_failure') as string);
            }
        }
        await this.setLockedDesk();
    }

    private get dateFilter() {
        const dateFilter = { beginDate: this.dateRange.start, endDate: this.dateRange.end };

        return dateFilter;
    }

    private get isSelectedDeskBookable() {
        const bookingsForSelectedDesk = this.getDeskBookings(this.selectedDeskId);

        return bookingsForSelectedDesk.length === 0;
    }

    private get userLogin() {
        return Bridge.user.login;
    }

    private async setDeskBookings() {
        this.bookings = await Bridge.getDeskBookings(undefined, this.dateFilter, undefined, this.isAdmin);
        this.bookings = this.bookings.filter(e => {
            if (this._duration === 'day') {
                return true;
            }
            return e.duration === this._duration || e.duration === 'day';
        });
        this.floorBookings = this.bookings.filter(e => e.floor === this._floor);
    }

    private async setLockedDesk() {
        this.lockedDesks = await Bridge.getLockedDesks(this._floor, this.dateFilter, this.isAdmin);
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000
        });
    }

    private async unbook() {
        if (this._duration === 'day' && this.isStartAndEndDateEqual) {
            const isSuccessful = await Bridge.unbookDesk(this.dateRange.start, this.dateRange.end, this.userLogin ?? '');

            if (isSuccessful) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
            }
        } else {
            const unbookingPromises: Array<Promise<boolean>> = [];

            for (let d = this.dateRange.start; d <= this.dateRange.end; d = d.plus({ day: 1 })) {
                unbookingPromises.push(Bridge.unbookDesk(d, d, this.userLogin ?? ''));
            }
            const isSuccessful = await Promise.all(unbookingPromises);

            if (isSuccessful.every(e => e === true)) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
            }
        }
        await this.setDeskBookings();
    }

    @Emit('floor-changed')
    changeFloor(floor: number) {
        return floor;
    }

    @Watch('_bookingToHighlight')
    onBookingToHighlight() {
        if (this._bookingToHighlight !== null) {
            this.$emit('reset-highlight');
        }
    }

    @Watch('_dateRange')
    async onDateRangeChange() {
        this.dateRange.start = DateTime.fromISO(this._dateRange.start).startOf('day');
        this.dateRange.end = DateTime.fromISO(this._dateRange.end).startOf('day');
        await this.setDeskBookings();
        await this.setLockedDesk();
    }

    @Watch('_duration')
    async onDurationChange() {
        await this.setDeskBookings();
        await this.setLockedDesk();
    }

    @Watch('_floor')
    async onFloorChange() {
        await this.setDeskBookings();
        await this.setLockedDesk();
    }

    @Watch('isAdmin')
    async onIsAdminChange() {
        await this.setDeskBookings();
        await this.setLockedDesk();
    }
}
