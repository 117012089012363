
import { User } from "@microsoft/microsoft-graph-types";
import { DateTime } from "luxon";
import { Component, Emit, Vue, Watch } from "vue-property-decorator";

import CalendarIcon from "@/assets/calendar.svg"
import UserSearchResult from "@/components/UserSearch/UserSearchResult.vue"
import * as Bridge from "@/services/Bridge";
import { listUsers } from "@/services/ConferenceRoom";
import { getMsalInstance } from "@/services/Msal";

@Component({
    components: {
        CalendarIcon,
        UserSearchResult
    }
})
export default class UserSearchModal extends Vue {
    public bookings: Array<DeskBookingResponse> = [];
    public date = DateTime.now().toISO();
    public displayBooking = false;
    public minDate = DateTime.now().startOf('day');
    public searchQuery = '';
    public selectedUser: User | undefined = undefined;
    public users: Array<User> = [];

    private formatEmail(email: string) {
        const splitted = email.split('@');

        return splitted[0];
    }

    private async setBookings(user: User) {
        if (user.mail !== null && typeof user.mail !== 'undefined') {
            const dateFilter: DateFilter = { beginDate: DateTime.fromISO(this.date).startOf('day'), endDate: DateTime.fromISO(this.date).startOf('day') };
            this.bookings = await Bridge.getDeskBookings(
                undefined,
                dateFilter,
                this.formatEmail(user.mail),
                false,
            );
        }
        this.displayBooking = true;
    }

    public async login() {
        await getMsalInstance()?.login();
    }

    public resetState() {
        this.bookings = [];
        this.date = DateTime.now().toISO();
        this.displayBooking = false;
        this.searchQuery = '';
        this.selectedUser = undefined;
        this.users = [];
    }

    public async selectUser(user: User) {
        this.selectedUser = user;
        if (typeof this.selectedUser !== 'undefined') {
            await this.setBookings(this.selectedUser);
        }
    }

    get isAuthenticated() {
        return getMsalInstance()?.isUserAuthenticated ?? false;
    }

    @Emit('on-booking-selected')
    public onBookingSelected(booking: DeskBookingResponse) {
        return booking;
    }

    @Watch('date')
    async onDateChange() {
        if (typeof this.selectedUser !== 'undefined') {
            await this.setBookings(this.selectedUser);
        }
    }

    @Watch('searchQuery')
    async onSearhgQueryChange() {
        this.bookings = [];
        this.displayBooking = false;
        this.selectedUser = undefined;
        this.users = [];
        if (this.searchQuery.length > 0) {
            this.users = await listUsers(
                {
                    logicalConnective: ['or'],
                    querries: [
                        { field: 'displayName', operator: 'startsWith', value: this.searchQuery },
                        { field: 'mail', operator: 'startsWith', value: this.searchQuery }
                    ]
                }
            );
        }
    }
}
