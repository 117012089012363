import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser"
import { ref } from "vue";

import { request, MsalConfig } from '@/config/MsalConfig'
import * as Bridge from "@/services/Bridge";

class MsalInstance {
    readonly msalInstance: PublicClientApplication;
    private _isAuth: boolean;

    constructor(config: MsalConfig) {
        this._isAuth = false;
        this.msalInstance = new PublicClientApplication(config.configuration);
    }

    get isUserAuthenticated() {
        return this._isAuth;
    }

    private activateAccount(account: AccountInfo | null | undefined) {
        if (account) {
            this.msalInstance.setActiveAccount(account);
            this._isAuth = true;
        }
    }

    private findAccountMatchingCurrentUser(accounts: Array<AccountInfo>) {
        const username = Bridge.user.login;

        if (typeof username != 'undefined') {
            return accounts.find(account => account.username.includes(username));
        }
    }

    private async getTokenWithoutUserInteraction() {
        const response = await this.msalInstance.acquireTokenSilent(request);

        return response.accessToken;
    }

    private async getTokenWithUserInteraction() {
        try {
            const response = await this.msalInstance.acquireTokenPopup(request);

            return response.accessToken;
        } catch {
            return '';
        }
    }

    public async getToken() {
        try {
            return await this.getTokenWithoutUserInteraction();
        } catch (e) {
            return e instanceof InteractionRequiredAuthError ? await this.getTokenWithUserInteraction() : '';
        }
    }

    public async login() {
        try {
            const response = await this.msalInstance.loginPopup(request);

            this.activateAccount(response.account);
        } catch (e) {
            console.error(e);
        }
    }

    public async logout() {
        try {
            await this.msalInstance.logoutPopup(request);
            this._isAuth = false;
        } catch (e) {
            console.error(e);
        }
    }

    public initialize() {
        const accounts = this.msalInstance.getAllAccounts();
        const account = this.findAccountMatchingCurrentUser(accounts);

        this.activateAccount(account);
    }

}

const msalInstance = ref<MsalInstance | undefined>(undefined);

export const getMsalInstance = () => msalInstance.value;

export const setMsalInstance = (config: MsalConfig) => msalInstance.value = new MsalInstance(config);
