
import { Component, Emit, Vue } from "vue-property-decorator";

import UserSearchModal from '@/components/UserSearch/UserSearchModal.vue';

@Component({
    components: {
        UserSearchModal
    }
})
export default class UserSearch extends Vue {

    @Emit('on-booking-selected')
    public onBookingSelected(booking: DeskBookingResponse) {
        this.$bvModal.hide('user-search-modal');
        return booking;
    }
}
