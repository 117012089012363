
import { DateTime } from 'luxon';
import { Component, Vue, PropSync } from "vue-property-decorator";

import CalendarIcon from '@/assets/calendar.svg';

@Component({
    components: {
        CalendarIcon
    }
})
export default class MobileMapFilter extends Vue {
    @PropSync("dateRange") public _dateRange!: DateRangeString;

    public maxDate = DateTime.now().startOf('day').plus({months: 2}).toISO();
    public minDate = DateTime.now().startOf('day').toISO();
    public modelConfig = { type: 'string' };
    public selectedDate = DateTime.now().startOf('day').toISO();

    public onSelectedDateChanged(val: string) {
        this._dateRange = {
            start: DateTime.fromISO(val).startOf('day').toISO() ?? DateTime.now().startOf('day').toISO(),
            end: DateTime.fromISO(val).startOf('day').toISO() ?? DateTime.now().startOf('day').toISO()
        }
    }
}
