
import { DateTime } from "luxon";
import { User } from "@microsoft/microsoft-graph-types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { createEvent } from "@/services/ConferenceRoom";
import UserSearch from "@/components/ConferenceRoom/UserSearch.vue"
import TimeSelector from "@/components/TimeSelector.vue"

@Component({
    components: {
        UserSearch,
        TimeSelector
    }
})
export default class ConferenceRoomTimeSlotBookingModal extends Vue {
    @Prop() public availabilityView!: Array<string>;
    @Prop() public startingTime!: DateTime;
    @Prop() public room!: ConferenceRoom | undefined;
    @Prop() public selectedDate!: string;

    public from = '';
    public to = '';
    public subject = '';
    public attendees: Array<string> = [];
    public isOnline = false;
    public users: Array<User> = [];
    public searchQuery = '';
    public errorMessage: string | undefined;

    private currentTime = DateTime.now();

    public setFrom(event: string) {
        this.from = event
    }

    public setTo(event: string) {
        this.to = event
    }

    public formatDateShort(date: string) {
        return DateTime.fromISO(date).setLocale('fr').toFormat("dd LLL yyyy");
    }

    public setTimeSlot() {
        this.from = `${this.startingTime.hour}:${this.startingTime.minute}:00`
        this.to = `${this.startingTime.hour + 1}:${this.startingTime.minute}:00`
    }

    private get startingSlot() {
        const from = this.from.split(':');
        const startingHour = (parseInt(from[0]) * 4) - 32;
        const startingMinute = parseInt(from[1]) / 15;

        return startingHour + startingMinute;
    }

    private get endingSlot() {
        const to = this.to.split(':');
        const endingHour = (parseInt(to[0]) * 4) - 32;
        const endingMinute = parseInt(to[1]) / 15;

        return endingHour + endingMinute;
    }

    private get isStartingTimeInThePast() {
        const startingTime = DateTime.fromISO(this.from);

        return this.currentTime > startingTime;
    }

    public get isTimeSlotValid() {
        this.errorMessage = undefined;

        if (this.from !== '' && this.to !== '') {
            const startingSlot = this.startingSlot;
            const endingSlot = this.endingSlot;

            if (this.currentTime.day === this.startingTime.day) {
                if (this.isStartingTimeInThePast) {
                    this.errorMessage = "Date_is_past"
                    return false;
                }
            }

            if (endingSlot > startingSlot && typeof this.availabilityView !== 'undefined') {
                const timeSlot = this.availabilityView.slice(startingSlot, endingSlot);

                if (timeSlot.every(e => e === '')) {
                    this.errorMessage = undefined;
                    return true
                } else
                    this.errorMessage = "Date_is_unavailable"
                return false;
            }
        }

        this.errorMessage = "Date_is_error"
        return false;
    }

    public async bookRoom() {
        if (this.room) {
            const fullFrom: string = this.selectedDate.substring(0, 10) + 'T' + this.from;
            const fullTo: string = this.selectedDate.substring(0, 10) + 'T' + this.to;

            try {
                await createEvent({
                    title: this.subject,
                    attendees: this.attendees.map(e => ({ address: e, name: undefined })),
                    startTime: DateTime.fromISO(fullFrom),
                    endTime: DateTime.fromISO(fullTo),
                    location: { address: this.room.address, name: this.room.name },
                    isOnlineMeeting: this.isOnline,
                })
                this.$emit('confirmed', [this.startingSlot + 1, this.endingSlot + 1]);
            } catch (e) {
                this.$emit('failure');
            } finally {
                this.$bvModal.hide(`${this.room.name}-booking-modal`);
                this.resetState();
            }
        }
    }

    @Watch('startingTime')
    onStartingTimeChange() {
        this.setTimeSlot();
    }

    resetState() {
        this.setTimeSlot();
        this.subject = '';
        this.attendees = [];
        this.isOnline = false;
        this.users = [];
        this.searchQuery = '';
    }
}
