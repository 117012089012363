
import { DateTime } from "luxon";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConferenceRoomTimeSlotPopover extends Vue {
    @Prop() public endingTime!: DateTime;
    @Prop() public roomName!: string;
    @Prop() public startingTime!: DateTime;
    @Prop() public timeslot!: number;
    @Prop() public user!: string;

    public formatHour(hour: number) {
        return hour < 10 ? `0${hour}` : hour;
    }

    public formatMinute(minute: number) {
        return minute === 0 ? `0${minute}` : minute;
    }
}
