
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import * as Bridge from '@/services/Bridge';
import deskCoordinatesJSON from '@/assets/configuration/desk_coordinates.json';

import ArrowIcon from '@/assets/arrow.svg';
import { getDiffInDay } from '@/services/Date';

@Component({
    components: {
        ArrowIcon
    }
})
export default class MobileFloorMap extends Vue {
    @Prop({ default: () => ({ start: DateTime.now().startOf('day').toISO(), end: DateTime.now().startOf('day').toISO() }) })
    readonly _dateRange!: DateRangeString;

    public lockedDesks: Array<LockedDeskResponses> = []
    public selectedDeskId = '';
    public selectedBench: Bench | null = null;
    public selectedRoomId = '';
    public selectedFloorId = 1;

    private bookings: Array<DeskBookingResponse> = [];

    public get benchSpacing() {
        return 2;
    }

    public get conflictingBooking(): DeskBookingResponse | Array<DeskBookingResponse> | null {
        const bookings = this.bookings.filter(e => e.login === this.userLogin);

        if (bookings.length === 0) {
            return null;
        }
        if (bookings.length === 1 && getDiffInDay(this.dateRange.start, this.dateRange.end) === 0) {
            return bookings[0];
        }
        return bookings;
    }

    public get coordinates() {
        return deskCoordinatesJSON as Array<DeskCoordinates>;
    }

    public get floors() {
        return [4, 3, 2, 1, 0, -1];
    }

    public get selectedBenchDesks() {
        if (this.selectedBench === null) {
            return [];
        }
        return this.selectedBench.desks;
    }

    public dateRange = {
        start: DateTime.fromISO(this._dateRange.start).startOf('day'),
        end: DateTime.fromISO(this._dateRange.end).startOf('day')
    }

    public async book() {
        const isSuccessful = await Bridge.bookDesk(this.dateRange.start, this.dateRange.end, 'day', this.selectedDeskId);

        if (isSuccessful) {
            this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
        } else {
            this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
        }
        await this.setDeskBookings();
    }

    public async created() {
        await this.setDeskBookings();
        await this.setLockedDesk();
    }

    public formatDateToFrLocale(date: DateTime) {
        return date.setLocale('fr').toLocaleString(DateTime.DATE_HUGE);
    }

    public formatDateToFrLocaleShort(date: DateTime) {
        return date.setLocale('fr').toFormat("dd LLL");
    }

    public getDeskOccupant(id: string) {
        const booking = this.bookings.find(e => e.id === id);

        if (this.isLocked(id)) {
            return this.$t('Unavailable');
        }
        if (typeof booking === 'undefined') {
            return this.$t('Available');
        }
        return this.$t('Booked_by_user', { user: booking.login });
    }

    public getFloorRooms(floor: number) {
        const desks = this.coordinates.find(e => e.floor === floor);

        if (typeof desks === 'undefined') {
            return [];
        }
        return desks.rooms;
    }

    public isBenchSelected(bench: Bench, room: Room) {
        if (room.room === this.selectedRoomId && this.selectedBench !== null) {
            return bench.bench === this.selectedBench.bench;
        }
        return false;
    }

    public isLocked(id: string) {
        return this.lockedDesks.some(e => e.id === id);
    }

    public getStyle(deskId: string, deskRepresentationId: string) {
        if (deskId !== deskRepresentationId) {
            return '';
        }
        const booking = this.bookings.find(e => e.id === deskId);

        if (typeof booking === 'undefined') {
            return '';
        }
        return booking.login === this.userLogin ? 'booked-by-user' : 'booked';
    }

    public openBookingModal(deskId: string) {
        this.selectedDeskId = deskId;

        if (this.isDeskBookable(deskId) && !this.isLocked(deskId)) {
            if (this.conflictingBooking === null) {
                this.$bvModal.show('new-reservation-modal-mobile');
            } else {
                this.$bvModal.show('replace-reservation-modal-mobile');
            }
        }
    }

    public async rebook() {
        await this.unbook();
        await this.book();
    }

    public setFloor(floor: number) {
        this.selectedBench = null;
        this.selectedFloorId = floor;
    }

    public toggleBench(roomId: string, bench: Bench) {
        this.selectedRoomId = roomId;
        this.selectedBench = bench;
    }

    private get isAdmin() {
        return Bridge.user.isAdmin && this.$root.$data.isAdminMode;
    }

    private isDeskBookable(deskId: string) {
        const bookingsForSelectedDesk = this.bookings
            .filter(e => e.id === deskId);

        return bookingsForSelectedDesk.length === 0;
    }

    private get userLogin() {
        return Bridge.user.login;
    }

    private async setDeskBookings() {
        const dateFilter = { beginDate: this.dateRange.start, endDate: this.dateRange.end };

        this.bookings = await Bridge.getDeskBookings(this.selectedFloorId, dateFilter, undefined, this.isAdmin);
    }

    private async setLockedDesk() {
        const dateFilter = { beginDate: this.dateRange.start, endDate: this.dateRange.end };

        this.lockedDesks = await Bridge.getLockedDesks(this.selectedFloorId, dateFilter, this.isAdmin);
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000
        });
    }

    private async unbook() {
        const isSuccessful = await Bridge.unbookDesk(this.dateRange.start, this.dateRange.end, this.userLogin ?? '');

        if (isSuccessful) {
            this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
        } else {
            this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
        }

        await this.setDeskBookings();
    }

    @Watch('selectedFloorId')
    async onFloorChange() {
        await this.setDeskBookings();
        await this.setLockedDesk();
    }

    @Watch('_dateRange')
    async onDateChange() {
        this.dateRange.start = DateTime.fromISO(this._dateRange.start).startOf('day');
        this.dateRange.end = DateTime.fromISO(this._dateRange.end).startOf('day');

        await this.setDeskBookings();
        await this.setLockedDesk();
    }
}
