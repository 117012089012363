var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"desktop"},[_c('MapFilter',{attrs:{"dateRange":_vm.dateRange,"duration":_vm.duration},on:{"update:dateRange":function($event){_vm.dateRange=$event},"update:date-range":function($event){_vm.dateRange=$event},"update:duration":function($event){_vm.duration=$event},"on-booking-selected":_vm.onBookingSelected}}),_c('div',{staticClass:"flex"},[_c('FloorMap',{attrs:{"_floor":_vm.floor,"_dateRange":_vm.dateRange,"_duration":_vm.duration,"_bookingToHighlight":_vm.bookingToHighlight},on:{"floor-changed":_vm.changeFloor,"conference-room-desk-booked":_vm.refreshScheduleComponent,"reset-highlight":_vm.resetHighlight}}),_c('div',{staticClass:"calendar-section"},[(_vm.isRoomInCurrentFloor())?_c('ConferenceRoomSchedule',{key:_vm.scheduleComponentKey,staticClass:"calendars",attrs:{"floor":_vm.floor,"date":_vm.dateRange.start,"isMobileView":false}}):_c('div',{staticClass:"empty-state"},[_vm._v("Aucune salle disponible")])],1)],1)],1),_c('div',{staticClass:"mobile"},[(_vm.mobilePanSelected === null)?_c('div',{staticClass:"mobiles-book-choices"},_vm._l((_vm.mobileChoices),function(choice){return _c('div',{key:choice.pan,staticClass:"mobiles-book-choice",on:{"click":function($event){return _vm.$router.push({
                query: {
                    pan: choice.pan
                }
            })}}},[_vm._v(" "+_vm._s(_vm.$t(choice.title))+" ")])}),0):_vm._e(),(_vm.mobilePanSelected !== null)?[_c('div',{staticClass:"mobile-back",on:{"click":function($event){return _vm.$router.push({
                query: { pan: null }
            })}}},[_vm._v("< "+_vm._s(_vm.$t(_vm.getTitleFromPanCode(_vm.mobilePanSelected))))]),(_vm.mobilePanSelected === _vm.Pans.BOOK)?[_c('MobileMapFilter',{attrs:{"dateRange":_vm.dateRange},on:{"update:dateRange":function($event){_vm.dateRange=$event},"update:date-range":function($event){_vm.dateRange=$event}}}),_c('MobileFloorMap',{attrs:{"_dateRange":_vm.dateRange}})]:_vm._e(),(_vm.mobilePanSelected === _vm.Pans.QR)?[_c('div',[_c('QrcodeScanner')],1)]:_vm._e(),(_vm.mobilePanSelected === _vm.Pans.PRIVATE)?[_c('div',[_vm._v("Private")])]:_vm._e()]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }