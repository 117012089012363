
import { Component, Vue } from "vue-property-decorator";

import router from "@/router";
import * as Bridge from "@/services/Bridge";
import { getMsalInstance } from "@/services/Msal";

@Component({})
export default class Login extends Vue {

  public created() {
    Bridge.isAuth().then(response => {
      if (response.status == 200)
        this.loadUserDetails();
    });
  }

  private loadUserDetails() {
    Bridge.loadUserDetails().then(response => {
      if (response.status == 200) {
        getMsalInstance()?.initialize();
        router.push('/book')
      }
    });
  }

  public signin() {
    Bridge.signIn();
  }
}
