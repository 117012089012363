import axios from "axios";
import { DateTime } from "luxon";
import { Attendee, EmailAddress, Event, ScheduleInformation, User } from "@microsoft/microsoft-graph-types";

import conferenceRoomJSON from '@/assets/configuration/conference_room.json'
import { getMsalInstance } from "@/services/Msal";

export const enum AvailabilityStatus {
  Free = '0',
  Tentative = '1',
  Busy = '2',
  OuOfOffice = '3',
  WorkingElsewhere = '4'
}

const advancedQueryBuilder = (queryFilter: McsAdvancedQuery) => {
  switch (queryFilter.operator) {
    case 'eq':
      return `${queryFilter.field} ${queryFilter.operator} '${queryFilter.value}'`;
    case 'startsWith':
      return `${queryFilter.operator}(${queryFilter.field}, '${queryFilter.value}')`;
  }
}

const attendeeListBuilder = (roomAddress: EmailAddress, attendeesAddresses: Array<EmailAddress>): Array<Attendee> => {
  const room: Attendee = { emailAddress: { address: roomAddress.address, name: roomAddress.name }, type: 'required' };
  const attendes: Array<Attendee> = attendeesAddresses.map(e => ({ emailAddress: e, type: 'optional' } as Attendee));

  return [room, ...attendes];
}

export const getConferenceRooms = (floor?: number): Array<ConferenceRoom> => {
  if (typeof floor === 'undefined') {
    return conferenceRoomJSON.reduce((accumulator, currentValue) => accumulator.concat(currentValue.rooms), [] as Array<ConferenceRoom>);
  }
  const result = conferenceRoomJSON.find(e => e.floor === floor);

  return typeof result === 'undefined' ? [] : result.rooms;
}

export const listEvents = async (day: DateTime, room: EmailAddress): Promise<Array<Event>> => {
  const token = await getMsalInstance()?.getToken() ?? '';

  if (token === '') {
    return [];
  }

  const endpoint = `https://graph.microsoft.com/v1.0/users/${room.address}/calendar/calendarView`;

  const config = {
    'headers':
    {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Prefer': 'outlook.timezone="Europe/Paris"'
    },
    'params': {
      'startDateTime': day.startOf('day').toISO({ includeOffset: false }),
      'endDateTime': day.startOf('day').plus({ day: 1 }).toISO({ includeOffset: false })
    }
  };

  const response = await axios.get<Array<Event>>(
    endpoint,
    config,
  );

  return response.data['value'];
}

export const getCurrentUserDisplayName = async (): Promise<string> => {
  const token = await getMsalInstance()?.getToken() ?? '';

  if (token === '') {
    return '';
  }

  const endpoint = `https://graph.microsoft.com/v1.0/me?$select=displayName`;

  const config = {
    'headers':
    {
      'Authorization': `Bearer ${token}`,
    }
  };

  const response = await axios.get<Array<User>>(
    endpoint,
    config
  );

  return response.data['displayName'];
};

export const listUsers = async (payload?: ListUsersPayload): Promise<Array<User>> => {
  const token = await getMsalInstance()?.getToken() ?? '';

  if (token === '') {
    return [];
  }

  const filters = typeof payload !== 'undefined' ?
    payload.querries.reduce(
      (previousValue, currentValue, index) =>
        previousValue + ' ' +
        advancedQueryBuilder(currentValue) +
        (typeof payload.logicalConnective.at(index) === 'undefined' ? '' : ` ${payload.logicalConnective[0]} `),
      '')
    : '';

  const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=${filters}`;

  const config = {
    'headers':
    {
      'Authorization': `Bearer ${token}`,
    }
  };

  const response = await axios.get<Array<User>>(
    endpoint,
    config
  );

  return response.data['value'];
};

export const createEvent = async (payload: CreateEventPayload): Promise<Event> => {
  const token = await getMsalInstance()?.getToken() ?? '';

  if (token === '') {
    return {};
  }

  const endpoint = `https://graph.microsoft.com/v1.0/me/calendar/events`;

  const config = {
    'headers':
    {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  };

  const body: Event = {
    subject: payload.title,
    attendees: attendeeListBuilder(payload.location, payload.attendees),
    start: {
      dateTime: payload.startTime.toISO({ includeOffset: false }),
      timeZone: 'Europe/Paris'
    },
    end: {
      dateTime: payload.endTime.toISO({ includeOffset: false }),
      timeZone: 'Europe/Paris'
    },
    location: {
      displayName: payload.location.name,
      locationEmailAddress: payload.location.address,
      locationType: 'conferenceRoom'
    },
    isOnlineMeeting: payload.isOnlineMeeting,
  }

  const response = await axios.post<Event>(
    endpoint,
    body,
    config
  );

  return response.data;
}

export const getDailySchedules = async (day: DateTime, addresses: Array<string>): Promise<Array<ScheduleInformation>> => {
  const token = await getMsalInstance()?.getToken() ?? '';

  if (token === '') {
    return [];
  }

  const endpoint = `https://graph.microsoft.com/v1.0/me/calendar/getSchedule`;

  const config = {
    'headers':
    {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Prefer': 'outlook.timezone="Europe/Paris"'
    }
  };

  const body = {
    'schedules': addresses,
    'startTime': {
      dateTime: day.toISO({ includeOffset: false }),
      timeZone: 'Europe/Paris'
    },
    'endTime': {
      dateTime: day.plus({ day: 1 }).toISO({ includeOffset: false }),
      timeZone: 'Europe/Paris'
    },
    'availabilityViewInterval': 15
  };

  const response = await axios.post<Array<ScheduleInformation>>(
    endpoint,
    body,
    config
  );

  return response.data['value'];
};
