
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import CalendarIcon from "@/assets/calendar.svg"

@Component({
    components: {
        CalendarIcon
    }
})
export default class UserSearchResult extends Vue {
    @Prop() public bookings!: Array<DeskBookingResponse>;

    @Emit('on-booking-selected')
    public onBookingSelected(booking: DeskBookingResponse) {
        return booking;
    }
}
