
import { Component, Vue } from "vue-property-decorator";

import ListIcon from '@/assets/icon_list.svg';
import MapIcon from '@/assets/icon_plan.svg';
import MeetingIcon from '@/assets/icon_meeting.svg';
import ProfileIcon from '@/assets/icon_profile.svg';
import ParkingIcon from '../assets/icon_parking.svg';
import * as Bridge from '@/services/Bridge';

@Component({
    components: {
        ListIcon,
        MapIcon,
        MeetingIcon,
        ProfileIcon,
        ParkingIcon
    }
})

export default class Navbar extends Vue {
    public login = Bridge.user.login;
    public isAdmin = Bridge.user.isAdmin;

    get isAdminMode() {
        return this.$root.$data.isAdminMode
    }

    set isAdminMode(value) {
        this.$root.$data.isAdminMode = !this.$root.$data.isAdminMode;
    }

    signOut() {
        Bridge.signOut();
    }
}
