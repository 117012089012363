
import { DateTime } from "luxon";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import parkingCoordinatesJSON from "@/assets/configuration/parking_coordinates.json";
import * as Bridge from "@/services/Bridge";
import { getDiffInDay } from "@/services/Date";

@Component({})
export default class MobileParkingMap extends Vue {
    @Prop({ default: () => ({ start: DateTime.now().startOf('day').toISO(), end: DateTime.now().startOf('day').toISO() }) })
    readonly _dateRange!: DateRangeString;
    @Prop() readonly _engine!: 'car' | 'moto';

    public bookings: Array<ParkingBookingResponse> = [];
    public dateRange = {
        start: DateTime.fromISO(this._dateRange.start).startOf('day'),
        end: DateTime.fromISO(this._dateRange.end).startOf('day')
    }
    public selectedParkingSpaceId = '';

    public get coordinates() {
        const coordinates = parkingCoordinatesJSON as Array<ParkingSpace>;

        return coordinates.filter(e => e.engine === this._engine);
    }

    public get areAllEnabledCarSpacesBooked() {
        const enabledCarSpaces = this.coordinates.filter(e => e.engine === 'car' && !e.isDisabled);

        return enabledCarSpaces.every(space => this.bookings.some(booking => booking.id === space.id));
    }

    public async book() {
        const isSuccessful = await Bridge.bookParkingSpace(this.dateRange.start, this.dateRange.end, 'day', this.selectedParkingSpaceId);

        if (isSuccessful) {
            this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
        } else {
            this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
        }

        await this.setParkingSpaceBookings();
    }

    public async created() {
        await this.setParkingSpaceBookings();
    }

    public formatDateToFrLocaleShort(date: DateTime) {
        return date.setLocale("fr").toFormat("dd LLL");
    }

    public getStyle(parkingSpace: ParkingSpace, isCurrent: boolean) {
        if (!isCurrent) {
            return '';
        }
        const isUnavailable = parkingSpace.isDisabled && !this.areAllEnabledCarSpacesBooked;
        const matchingBookedParking = this.bookings.find(e => e.id === parkingSpace.id);
        let parkingState = '';

        if (isUnavailable) {
            parkingState = 'unavailable';
        }
        if (!!matchingBookedParking) {
            parkingState = matchingBookedParking.login === this.userLogin ? 'booked-by-user' : 'booked';
        }
        return parkingState;
    }

    public getParkingSpaceOccupant(space: ParkingSpace) {
        const booking = this.bookings.find(e => e.id === space.id);

        if (space.isDisabled && !this.areAllEnabledCarSpacesBooked) {
            return this.$t('Unavailable');
        }
        if (typeof booking === 'undefined') {
            return this.$t('Available');
        }
        return this.$t('Booked_by_user', { user: booking.login });
    }

    public openBookingModal(id: string) {
        this.selectedParkingSpaceId = id;
        if (this.isSelectedParkingSpaceBookable) {
            if (this.conflictingBooking === null) {
                this.$bvModal.show('mobile-new-reservation-modal');
            } else {
                this.$bvModal.show('mobile-replace-reservation-modal');
            }
        }
    }

    public get conflictingBooking(): ParkingBookingResponse | Array<ParkingBookingResponse> | null {
        const bookings = this.bookings.filter(e => e.login === this.userLogin);

        if (bookings.length === 0) {
            return null;
        }
        if (bookings.length === 1 && getDiffInDay(this.dateRange.start, this.dateRange.end) === 0) {
            return bookings[0];
        }
        return bookings;
    }

    public async rebook() {
        await this.unbook();
        await this.book();
    }

    private get isSelectedParkingSpaceBookable() {
        const bookingsForSelectedParkingSpace = this.bookings
            .filter(e => e.id === this.selectedParkingSpaceId);

        return bookingsForSelectedParkingSpace.length === 0;
    }

    private get userLogin() {
        return Bridge.user.login;
    }

    private async setParkingSpaceBookings() {
        const dateFilter: DateFilter = { beginDate: this.dateRange.start, endDate: this.dateRange.start };

        this.bookings = await Bridge.getParkingSpaceBookings(dateFilter, undefined, false);
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000,
        });
    }

    private async unbook() {
        const isSuccessful = await Bridge.unbookParkingSpace(this.dateRange.start, this.dateRange.end, this.userLogin ?? '');

        if (isSuccessful) {
            this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
        } else {
            this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
        }
        await this.setParkingSpaceBookings();
    }

    @Watch('_dateRange')
    async onDateRangeChange() {
        this.dateRange.start = DateTime.fromISO(this._dateRange.start).startOf('day');
        this.dateRange.end = DateTime.fromISO(this._dateRange.end).startOf('day');
        await this.setParkingSpaceBookings();
    }
}
