/* eslint @typescript-eslint/camelcase: "off" */

import axios from "axios";
import { DateTime } from "luxon";

import { getEnvironmentConfig } from "@/config/Environment";

export const user: {
    login: string | undefined;
    isAdmin: boolean;
} = {
    login: undefined,
    isAdmin: false
};

const HTTP_OPTIONS = { withCredentials: true };

const runTask = async <T>(task: string, params = {}) => {
    return axios.post<T>(
        `${getEnvironmentConfig().bridge.url}task/run/${task}`,
        {
            "INPUT_VARS": params
        }, HTTP_OPTIONS
    );
}

export const isAuth = async () => {
    return await axios.get(`${getEnvironmentConfig().bridge.url}isauth`, HTTP_OPTIONS);
}

export const signIn = () => {
    window.location.href = `${getEnvironmentConfig().bridge.url}auth?allowRedirection=true`
}

export const signOut = async () => {
    return await axios.get(`${getEnvironmentConfig().bridge.url}logout`, HTTP_OPTIONS).then(() => {
        document.location.reload();
    });
}

export const loadUserDetails = async () => {
    return await runTask<any>("user_details").then(response => {
        user.login = response.data['login'];
        user.isAdmin = !!+response.data['is_admin'];
        return response;
    });
}

export const cleanUserDetails = () => {
    delete user.login;
    user.isAdmin = false;
}

export const getDeskBookings = async (
    floorFilter?: number,
    dateFilter?: DateFilter,
    userLoginFilter?: string,
    isLimitedToCurrentUser = true,
    paginate: PaginationPayload = { paginate: false }
): Promise<Array<DeskBookingResponse>> => {
    const payload = {
        'is_limited_to_current_user': isLimitedToCurrentUser,
        filter_on_floor: typeof floorFilter !== 'undefined',
        filter_on_login: typeof userLoginFilter !== 'undefined',
        filter_on_date: typeof dateFilter !== 'undefined',
        ...(typeof floorFilter !== 'undefined' && { floor: floorFilter }),
        ...(typeof dateFilter !== 'undefined' && { begin_date: dateFilter.beginDate.toSQLDate(), end_date: dateFilter.endDate.toSQLDate() }),
        ...(typeof userLoginFilter !== 'undefined' && { login: userLoginFilter }),
        ...paginate,
    };
    const response = await runTask<Array<RawDeskBookingResponse>>('booking/get', payload);
    const bookings = response.data.map((booking) => ({ ...booking, beginDate: DateTime.fromSQL(booking.beginDate), endDate: DateTime.fromSQL(booking.endDate) }));

    return bookings;
}

export const getDeskBookingCount = async (
    floorFilter?: number,
    dateFilter?: DateFilter,
    userLoginFilter?: string,
    isLimitedToCurrentUser = true,
): Promise<number> => {
    const payload = {
        'is_limited_to_current_user': isLimitedToCurrentUser,
        filter_on_floor: typeof floorFilter !== 'undefined',
        filter_on_login: typeof userLoginFilter !== 'undefined',
        filter_on_date: typeof dateFilter !== 'undefined',
        ...(typeof floorFilter !== 'undefined' && { floor: floorFilter }),
        ...(typeof dateFilter !== 'undefined' && { begin_date: dateFilter.beginDate.toSQLDate(), end_date: dateFilter.endDate.toSQLDate() }),
        ...(typeof userLoginFilter !== 'undefined' && { login: userLoginFilter }),
    };
    const response = await runTask<Array<CountResponse>>('booking/count', payload);
    const count = response.data.length === 1 ? response.data[0].count : 0;

    return count;
}

export const bookDesk = async (beginDate: DateTime, endDate: DateTime, duration: BookingDuration, deskId: string) => {
    const payload = {
        'begin_date': beginDate.toSQLDate(),
        'end_date': endDate.toSQLDate(),
        'duration': duration,
        'desk': deskId
    };
    try {
        const response = await runTask<Array<RawResponse>>('desk/book', payload);

        return response.data[0].days > 0;
    } catch {
        return false;
    }
}

export const unbookDesk = async (beginDate: DateTime, endDate: DateTime, login: string) => {
    const payload = {
        'login': login,
        'begin_date': beginDate.toSQLDate(),
        'end_date': endDate.toSQLDate()
    };
    try {
        const response = await runTask<Array<RawResponse>>('desk/unbook', payload);

        return response.data[0].days > 0;
    } catch {
        return false;
    }
}

export const getLockedDesks = async (
    floorFilter?: number,
    dateFilter?: DateFilter,
    isLimitedToCurrentUser = true,
): Promise<Array<LockedDeskResponses>> => {
    const payload = {
        'is_limited_to_current_user': isLimitedToCurrentUser,
        filter_on_floor: typeof floorFilter !== 'undefined',
        filter_on_date: typeof dateFilter !== 'undefined',
        ...(typeof floorFilter !== 'undefined' && { floor: floorFilter }),
        ...(typeof dateFilter !== 'undefined' && { begin_date: dateFilter.beginDate.toSQLDate(), end_date: dateFilter.endDate.toSQLDate() }),
    };
    const response = await runTask<Array<RawLockedDeskResponses>>('booking/getLocked', payload);
    const bookings = response.data.map((booking) => ({ ...booking, beginDate: DateTime.fromSQL(booking.beginDate), endDate: DateTime.fromSQL(booking.endDate) }));

    return bookings;
}

export const lockDesk = async (beginDate: DateTime, deskId: string) => {
    const payload = {
        'date': beginDate.toSQLDate(),
        'desk': deskId
    };
    try {
        const response = await runTask<Array<RawResponse>>('desk/lock', payload);

        return response.data[0].days > 0;
    } catch {
        return false;
    }
}

export const unlockDesk = async (date: DateTime, deskId: string) => {
    const payload = {
        'date': date.toSQLDate(),
        'desk': deskId
    };
    try {
        const response = await runTask<Array<RawResponse>>('desk/lock', payload);

        return response.data[0].days > 0;
    } catch {
        return false;
    }
}

export const getParkingSpaceBookings = async (
    dateFilter?: DateFilter,
    userLoginFilter?: string,
    isLimitedToCurrentUser = true,
    paginate: PaginationPayload = { paginate: false }
): Promise<Array<ParkingBookingResponse>> => {
    const payload = {
        'is_limited_to_current_user': isLimitedToCurrentUser,
        filter_on_login: typeof userLoginFilter !== 'undefined',
        filter_on_date: typeof dateFilter !== 'undefined',
        ...(typeof dateFilter !== 'undefined' && { begin_date: dateFilter.beginDate.toSQLDate(), end_date: dateFilter.endDate.toSQLDate() }),
        ...(typeof userLoginFilter !== 'undefined' && { login: userLoginFilter }),
        ...paginate,
    };
    const response = await runTask<Array<RawParkingBookingResponse>>('parking/get', payload);
    const bookings = response.data.map((booking) => ({ ...booking, beginDate: DateTime.fromSQL(booking.beginDate), endDate: DateTime.fromSQL(booking.endDate) }));

    return bookings;
}

export const getParkingSpaceBookingCount = async (
    dateFilter?: DateFilter,
    userLoginFilter?: string,
    isLimitedToCurrentUser = true,
): Promise<number> => {
    const payload = {
        'is_limited_to_current_user': isLimitedToCurrentUser,
        filter_on_login: typeof userLoginFilter !== 'undefined',
        filter_on_date: typeof dateFilter !== 'undefined',
        ...(typeof dateFilter !== 'undefined' && { begin_date: dateFilter.beginDate.toSQLDate(), end_date: dateFilter.endDate.toSQLDate() }),
        ...(typeof userLoginFilter !== 'undefined' && { login: userLoginFilter }),
    };
    const response = await runTask<Array<CountResponse>>('parking/count', payload);
    const count = response.data.length === 1 ? response.data[0].count : 0;

    return count;
}

export const bookParkingSpace = async (beginDate: DateTime, endDate: DateTime, duration: BookingDuration, parkingSpaceId: string) => {
    const payload = {
        'begin_date': beginDate.toSQLDate(),
        'end_date': endDate.toSQLDate(),
        'duration': duration,
        'parking': parkingSpaceId
    };
    try {
        const response = await runTask<Array<RawResponse>>('space/book', payload);

        return response.data[0].days > 0;
    } catch {
        return false;
    }
}

export const unbookParkingSpace = async (beginDate: DateTime, endDate: DateTime, login: string) => {
    const payload = {
        'login': login,
        'begin_date': beginDate.toSQLDate(),
        'end_date': endDate.toSQLDate()
    };
    try {
        const response = await runTask<Array<RawResponse>>('space/unbook', payload);

        return response.data[0].days > 0;
    } catch {
        return false;
    }
}
