
import { Component, Vue, Watch } from "vue-property-decorator";
import { DateTime } from 'luxon';

import ConferenceRoomSchedule from "@/components/ConferenceRoom/ConferenceRoomSchedule.vue";
import FloorMap from "@/components/FloorMap.vue";
import MapFilter from "@/components/MapFilter.vue";
import MobileMapFilter from "@/components/MobileMapFilter.vue";
import MobileFloorMap from "@/components/MobileFloorMap.vue";
import QrcodeScanner from "@/components/QrcodeScanner.vue";
import { getConferenceRooms } from "@/services/ConferenceRoom";

const DEFAULT_FLOOR = 1;

enum Pans {
    BOOK = 'book',
    QR = 'qr',
    PRIVATE = 'private'
}

interface Choice {
    title: string;
    pan: Pans;
}

@Component({
    components: {
        MapFilter,
        FloorMap,
        MobileMapFilter,
        MobileFloorMap,
        QrcodeScanner,
        ConferenceRoomSchedule
    },
})
export default class Book extends Vue {
    Pans = Pans;
    floor: number = DEFAULT_FLOOR;

    public dateRange: DateRangeString = {
        start: DateTime.now().startOf('day').toISO(),
        end: DateTime.now().startOf('day').toISO(),
    }
    public duration: BookingDuration = 'day';
    public mobilePanSelected: Pans | null = this.$route.query.pan ? String(this.$route.query.pan) as Pans : null;
    public mobileChoices: Choice[] = [
        {
            title: 'new_booking',
            pan: Pans.BOOK
        }
    ];

    // TODO : to remove when QR code is up 
    // public mobileChoices: Choice[] = [
    //     {
    //         title: 'new_booking',
    //         pan: Pans.BOOK
    //     },
    //     {
    //         title: 'scan_with_qr',
    //         pan: Pans.QR
    //     },
    // ];
    public scheduleComponentKey = false;
    public bookingToHighlight: DeskBookingResponse | null = null;

    public refreshScheduleComponent() {
        this.scheduleComponentKey = !this.scheduleComponentKey;
    }

    public resetHighlight() {
        setTimeout(() => this.bookingToHighlight = null, 2000);
    }

    changeFloor(floorNb: number) {
        this.floor = floorNb;
    }

    public onBookingSelected(booking: DeskBookingResponse) {
        const selectedDate = booking.beginDate.startOf('day').toISO();

        if (booking !== null && this.floor !== booking.floor) {
            this.floor = booking.floor;
        }
        if (booking !== null && this.dateRange.start !== selectedDate) {
            if (selectedDate !== null) {
                this.dateRange = { start: selectedDate, end: selectedDate };
            }
        }
        setTimeout(() => this.bookingToHighlight = booking, 1000);
    }

    @Watch('$route.query')
    onRouteQueryChange(val: { pan: Pans }) {
        this.mobilePanSelected = val.pan ?? null;

    }

    public created() {
        if (this.$route.query.pan === undefined) {
            this.$router.replace({
                query: {
                    pan: null
                }
            });
        }
    }

    public isRoomInCurrentFloor() {
        const rooms = getConferenceRooms(this.floor);

        return rooms ? rooms.length > 0 : false;
    }


    public getTitleFromPanCode(pan: Pans): string {
        return this.mobileChoices.find((mc) => mc.pan === pan)?.title ?? "";
    }

}
