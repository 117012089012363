import { DateTime } from 'luxon';

export function formatToISO(date: number) {
    return DateTime.fromMillis(date).toISODate();
}

function resetTime(date: DateTime | Date) {
    if (date instanceof DateTime) {
        return date.startOf('day')
    } else {
        return DateTime.fromJSDate(date)
    }
}

export function isSameDayOrAfter(date: DateTime, comparedTo: DateTime) {
    return resetTime(date) >= resetTime(comparedTo);
}

export function isBefore(date: DateTime, comparedTo: DateTime) {
    return resetTime(date) < resetTime(comparedTo);
}

export function intersectsRange(inputStart: DateTime, inputEnd: DateTime, rangeStart: DateTime, rangeEnd: DateTime) {
    return !(resetTime(rangeEnd) < resetTime(inputStart) || resetTime(rangeStart) > resetTime(inputEnd))
}

export function getTomorrowsDate() {
    const today = DateTime.now()

    return today.plus({ days: 1 });
}

export const getDiffInDay = (start: DateTime, end: DateTime) => {
    const diff = start.diff(end, ['days']);

    return diff.days;
}