
import { Component, Vue } from "vue-property-decorator";

import {version} from '../package.json';

import MobileNavbar from "@/components/MobileNavbar.vue";
import Navbar from "@/components/Navbar.vue";
import * as Bridge from "@/services/Bridge";

@Component({
    components: {
        Navbar,
        MobileNavbar,
    },
})
export default class App extends Vue {
    appVersion = version;

    constructor() {
        super();
    }

    public isAuth() {
        return Bridge.user.login != undefined;
    }
}
