import axios from "axios";
import { ref } from 'vue';

type Environment = {
    "bridge": {
        "url": string;
    };
    "msal": {
        "clientId": string;
        "tenantId": string;
    };
};

const environmentConfig = ref<Environment>({
    "bridge": {
        "url": ''
    },
    "msal": {
        "clientId": '',
        "tenantId": ''
    }
})

export const getEnvironmentConfig = () => environmentConfig.value;

export const loadEnvironment = async () => {
    const response = await axios.get<Environment>('config.env.json');

    environmentConfig.value = response.data;
}