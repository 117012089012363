
import { NullableOption, User } from "@microsoft/microsoft-graph-types";
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";

import { getCurrentUserDisplayName, listUsers } from "@/services/ConferenceRoom";

@Component
export default class UserSearch extends Vue {
    @PropSync('attendees') _attendees!: Array<string>;
    public users: Array<User> = [];
    public searchQuery = '';

    public addAttendee(mail: NullableOption<string> | undefined) {
        if (mail !== null && typeof mail !== 'undefined') {
            this._attendees.push(mail);
            this.users = [];
            this.searchQuery = '';
        }
    }

    public removeAttendee(mail: string) {
        this._attendees = this._attendees.filter(e => e !== mail);
    }

    public isUserAlreadyInvited(mail: NullableOption<string> | undefined) {
        if (mail !== null && typeof mail !== 'undefined') {
            return this._attendees.some(e => e === mail);
        }
        return true;
    }

    public getUserNameFromEmail(email: string) {
        const splitted = email.split('@');

        return splitted[0];
    }

    @Watch('searchQuery')
    async onSearhgQueryChange() {
        this.users = [];
        if (this.searchQuery.length > 0) {
            this.users = await listUsers(
                {
                    logicalConnective: ['or'],
                    querries: [
                        { field: 'displayName', operator: 'startsWith', value: this.searchQuery },
                        { field: 'mail', operator: 'startsWith', value: this.searchQuery }
                    ]
                }
            );
        }
        await getCurrentUserDisplayName();
    }
}
