
import { DateTime } from 'luxon';
import { Component, Prop, Vue } from "vue-property-decorator"

import CalendarIcon from '@/assets/calendar.svg';
import MobileMapFilter from "@/components/MobileMapFilter.vue";
import router from "@/router";
import * as Bridge from "@/services/Bridge";

@Component({
    name: "MobileBook",
    components: {
        CalendarIcon,
        MobileMapFilter
    }
})
export default class MobileBook extends Vue {
    @Prop() readonly _desk!: DecodedDesk;
    public dateRange: DateRangeString = {
        start: DateTime.now().startOf('day').toISO(),
        end: DateTime.now().startOf('day').toISO()
    }

    public async confirmBooking() {
        const userAlreadyHaveABooking = this.bookings.some(({ login }) => login === this.currentUser);

        if (userAlreadyHaveABooking) {
            await this.rebook();
        } else {
            await this.book();
        }
    }

    private bookings: Array<DeskBookingResponse> = [];
    private currentUser = Bridge.user.login;

    async created() {
        await this.setDeskBookings();
    }

    private get dateFilter() {
        const dateFilter: DateFilter = { beginDate: DateTime.fromISO(this.dateRange.start), endDate: DateTime.fromISO(this.dateRange.end) };

        return dateFilter;
    }

    private async book() {
        const isSuccessful = await Bridge.bookDesk(this.dateFilter.beginDate, this.dateFilter.endDate, 'day', this._desk.id);

        if (isSuccessful) {
            this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
        } else {
            this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string);
        }
        setTimeout(() => router.push({ path: '/book', query: { pan: null } }), 1000);
    }

    private async setDeskBookings() {
        this.bookings = await Bridge.getDeskBookings(undefined, this.dateFilter, undefined, false);
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000
        });
    }

    private async rebook() {
        await this.unbook();
        await this.book();
    }

    private async unbook() {
        const isSuccessful = await Bridge.unbookDesk(this.dateFilter.beginDate, this.dateFilter.endDate, this.currentUser ?? '');

        if (isSuccessful) {
            this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
        } else {
            this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
        }
    }
}
