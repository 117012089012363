import { Configuration, PopupRequest } from "@azure/msal-browser";

export class MsalConfig {
    configuration: Configuration;

    constructor(clientId: string, tenantId: string) {
        this.configuration = {
            auth: {
                clientId: clientId,
                authority: `https://login.microsoftonline.com/${tenantId}`,
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
            },
        }
    }
}

export const request: PopupRequest = {
    scopes: ["offline_access", "User.Read", "User.ReadBasic.All", "Calendars.ReadWrite"]
};
