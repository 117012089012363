
import { Component, Vue } from "vue-property-decorator";
import { QrcodeStream } from "vue-qrcode-reader";
import { DateTime } from "luxon";

import MobileBook from "@/components/MobileBook.vue";
import * as Bridge from "@/services/Bridge";

@Component({
    name: "QrcodeScanner",
    components: {
        MobileBook,
        QrcodeStream,
    },
})
export default class QrcodeScanner extends Vue {
    public bookings: Array<DeskBookingResponse> = [];
    public decodedDesk: DecodedDesk = { id: '', building: '', floor: 0, room: 0, bench: 0, desk: 0 };
    public isReadingSuccessful = false;

    public async created() {
        await this.setDeskBookings();
    }

    public decode(stringToDecode: string) {
        const regex = /^B([A-Z][0-9]+)F([0-9]+)R([0-9]+)B([0-9]+)D([0-9]+)$/;
        const decodedString = stringToDecode.match(regex);

        if (decodedString) {
            try {
                this.decodedDesk.id = stringToDecode;
                this.decodedDesk.building = decodedString[1];
                this.decodedDesk.floor = +decodedString[2];
                this.decodedDesk.room = +decodedString[3];
                this.decodedDesk.bench = +decodedString[4];
                this.decodedDesk.desk = +decodedString[5];

                if (this.isDeskBooked) {
                    this.showToast(this.$t("Failure") as string, this.$t("Desk_already_booked") as string);
                } else {
                    this.isReadingSuccessful = true;
                }
            } catch {
                this.showToast(this.$t("Failure") as string, this.$t("Invalid_qr_code") as string);
            }
        } else {
            this.showToast(this.$t("Failure") as string, this.$t("Invalid_qr_code") as string);
        }
    }

    private currentUser = Bridge.user.login;

    private get isDeskBooked() {
        return this.bookings.some((booking) =>
            booking.building === this.decodedDesk.building &&
            booking.floor === this.decodedDesk.floor &&
            booking.room === this.decodedDesk.room &&
            booking.bench === this.decodedDesk.bench &&
            booking.desk === this.decodedDesk.desk &&
            booking.login !== this.currentUser
        );
    }

    private async setDeskBookings() {
        const dateFilter = { beginDate: DateTime.now().startOf('day'), endDate: DateTime.now().startOf('day') };
        this.bookings = await Bridge.getDeskBookings(undefined, dateFilter, undefined, false);
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000,
        });
    }
}
