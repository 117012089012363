
import { DateTime } from "luxon";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { user } from "@/services/Bridge";
import { createEvent } from "@/services/ConferenceRoom";

@Component
export default class ConferenceRoomDeskBookingModal extends Vue {
    @Prop() public date!: string;
    @Prop() public isMorningBooked!: boolean;
    @Prop() public isAfternoonBooked!: boolean;
    @Prop() public isDayBooked!: boolean;
    @Prop() public room!: ConferenceRoom;

    public isBookingMorning = false;
    public isBookingAfternoon = false;
    public isBookingDay = false;

    private getStartingTime() {
        if (this.isBookingMorning || this.isBookingDay) {
            return DateTime.fromISO(this.date).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        }
        if (this.isBookingAfternoon) {
            return DateTime.fromISO(this.date).set({ hour: 14, minute: 0, second: 0, millisecond: 0 });
        }
    }

    private getEndingTime() {
        if (this.isBookingAfternoon || this.isBookingDay) {
            return DateTime.fromISO(this.date).set({ hour: 20, minute: 0, second: 0, millisecond: 0 });
        }
        if (this.isBookingMorning) {
            return DateTime.fromISO(this.date).set({ hour: 14, minute: 0, second: 0, millisecond: 0 });
        }
    }

    public formatDateShort(date: string) {
        return DateTime.fromISO(date).setLocale('fr').toFormat("dd LLL");
    }

    public resetState() {
        this.isBookingMorning = false;
        this.isBookingAfternoon = false;
        this.isBookingDay = false;
    }

    @Emit('conference-room-booked')
    async bookRoom() {
        await createEvent({
            title: `${user.login}`,
            attendees: [],
            startTime: this.getStartingTime(),
            endTime: this.getEndingTime(),
            location: { address: this.room.address, name: this.room.name },
            isOnlineMeeting: false,
        });
        this.resetState();
    }

    get isConfirmationEnabled() {
        return this.isBookingMorning || this.isBookingAfternoon || this.isBookingDay;
    }
}
