
import { DateTime } from "luxon";
import { DateTimeTimeZone, NullableOption } from "@microsoft/microsoft-graph-types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ConferenceRoomDeskBookingModal from "@/components/ConferenceRoom/ConferenceRoomDeskBookingModal.vue";
import { getDailySchedules } from "@/services/ConferenceRoom";
import { getMsalInstance } from "@/services/Msal";

@Component({
    components: {
        ConferenceRoomDeskBookingModal
    }
})
export default class ConferenceRoomDesk extends Vue {
    @Prop() public date!: string;
    @Prop() public room!: ConferenceRoom;

    public isMorningBooked = false;
    public isAfternoonBooked = false;

    private isEventStartingInTheMorning(eventStart: NullableOption<DateTimeTimeZone> | undefined) {
        if (eventStart && eventStart.dateTime) {
            const startTime = DateTime.fromISO(eventStart.dateTime);

            return startTime.hour < 14;
        }
        return false;
    }

    private isEventEndingInTheAfternoon(eventEnd: NullableOption<DateTimeTimeZone> | undefined) {
        if (eventEnd && eventEnd.dateTime) {
            const endTime = DateTime.fromISO(eventEnd.dateTime);

            return endTime.hour > 14;
        }
        return false;
    }

    public async setBookedStatus() {
        const schedules = await getDailySchedules(DateTime.fromISO(this.date).startOf('day'), [this.room.address]);

        for (const schedule of schedules) {
            if (schedule.scheduleItems) {
                this.isMorningBooked = schedule.scheduleItems.some(e => this.isEventStartingInTheMorning(e.start));
                this.isAfternoonBooked = schedule.scheduleItems.some(e => this.isEventEndingInTheAfternoon(e.end));
            }
        }
    }

    public async login() {
        await getMsalInstance()?.login();
        await this.setBookedStatus();
    }

    public showModal() {
        if (!this.isFullyBooked) {
            this.$bvModal.show(this.room.name);
        }
    }

    public refreshAfterBooking() {
        setTimeout(async () => {
            await this.setBookedStatus();
            if (this.$parent !== null) {
                this.$parent.$emit('conference-room-desk-booked');
            }
        }, 6000);
    }

    get isAuthenticated() {
        return getMsalInstance()?.isUserAuthenticated ?? false;
    }

    get isFullyBooked() {
        return this.isMorningBooked && this.isAfternoonBooked;
    }

    async mounted() {
        await this.setBookedStatus();
    }

    @Watch("date")
    async onDateChange() {
        await this.setBookedStatus();
    }

    @Watch("room")
    async onRoomChange() {
        await this.setBookedStatus();
    }

    @Watch("isAuthenticated")
    async onIsAuthenticatedChange() {
        await this.setBookedStatus();
    }
}
