
import { DateTime } from "luxon";
import { Component, Vue, Watch } from "vue-property-decorator";

import MapFilter from "@/components/MapFilter.vue";
import MobileMapFilter from "@/components/MobileMapFilter.vue";
import MobileParkingMap from "@/components/MobileParkingMap.vue";
import ParkingMap from "@/components/ParkingMap.vue";

enum Pans {
    CAR = 'car',
    MOTO = 'moto',
}

interface Choice {
    title: string;
    pan: Pans;
}

@Component({
    components: {
        MapFilter,
        ParkingMap,
        MobileParkingMap,
        MobileMapFilter
    },
})
export default class Parking extends Vue {
    public dateRange: DateRangeString = {
        start: DateTime.now().startOf('day').toISO(),
        end: DateTime.now().startOf('day').toISO(),
    }
    public duration: BookingDuration = 'day';

    Pans = Pans;
    public mobilePanSelected: Pans | null = this.$route.query.pan ? String(this.$route.query.pan) as Pans : null;
    public mobileChoices: Choice[] = [
        {
            title: 'New_car_parking_booking',
            pan: Pans.CAR
        },
        {
            title: 'New_moto_parking_booking',
            pan: Pans.MOTO
        }
    ];

    public getTitleFromPanCode(pan: Pans): string {
        return this.mobileChoices.find((mc) => mc.pan === pan)?.title ?? "";
    }

    @Watch('$route.query')
    onRouteQueryChange(val: { pan: Pans }) {
        this.mobilePanSelected = val.pan ?? null;
    }
}
