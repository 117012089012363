
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';

import parkingCoordinatesJSON from "@/assets/configuration/parking_coordinates.json";
import * as Bridge from '@/services/Bridge';
import { getDiffInDay } from '@/services/Date';

@Component({})
export default class ParkingMap extends Vue {
    @Prop({ default: () => ({ start: DateTime.now().startOf('day').toISO(), end: DateTime.now().startOf('day').toISO() }) })
    readonly _dateRange!: DateRangeString;
    @Prop({ default: 'day' })
    readonly _duration!: BookingDuration;

    public bookings: Array<ParkingBookingResponse> = [];
    public dateRange = {
        start: DateTime.fromISO(this._dateRange.start).startOf('day'),
        end: DateTime.fromISO(this._dateRange.end).startOf('day')
    }
    public selectedParkingSpaceId = '';

    public get areAllEnabledCarSpacesBooked() {
        const enabledCarSpaces = this.coordinates.filter(e => e.engine === 'car' && !e.isDisabled);

        if (this._duration === 'day' && (this.dateRange.start.day !== this.dateRange.end.day)) {
            return false;
        }

        return enabledCarSpaces.every(space => this.bookings.some(booking => booking.id === space.id));
    }

    public get conflictingBooking(): ParkingBookingResponse | Array<ParkingBookingResponse> | null {
        const bookings = this.bookings.filter(e => e.login === this.userLogin);

        if (bookings.length === 0) {
            return null;
        }
        if (bookings.length === 1 && this.isStartAndEndDateEqual) {
            return bookings[0];
        }
        return bookings;
    }

    public get coordinates() {
        return parkingCoordinatesJSON as Array<ParkingSpace>;
    }

    public get modalI18nPath() {
        if (this._duration === 'day' && this.isStartAndEndDateEqual) {
            return 'Parking_reservation_info_one_day';
        }
        if (this._duration === 'morning') {
            return 'Parking_reservation_info_morning';
        }
        if (this._duration === 'afternoon') {
            return 'Parking_reservation_info_afternoon';
        }
        return 'Parking_reservation_info';
    }

    public async book() {
        if (this._duration === 'day' && this.isStartAndEndDateEqual) {
            const isSuccessful = await Bridge.bookParkingSpace(this.dateRange.start, this.dateRange.end, this._duration, this.selectedParkingSpaceId);

            if (isSuccessful) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
            }
        } else {
            const bookingPromises: Array<Promise<boolean>> = [];

            for (let d = this.dateRange.start; d <= this.dateRange.end; d = d.plus({ day: 1 })) {
                bookingPromises.push(Bridge.bookParkingSpace(d, d, this._duration, this.selectedParkingSpaceId));
            }

            const isSuccessful = await Promise.all(bookingPromises);

            if (isSuccessful.every(e => e === true)) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
            }
        }
        await this.setParkingSpaceBookings();
    }

    public async created() {
        await this.setParkingSpaceBookings();
    }

    public formatDateToFrLocale(beginDate: DateTime, duration: string) {
        return beginDate.setLocale('fr').toFormat(`DDDD ' - ${this.$t(duration)}'`);
    }

    public formatDateToFrLocaleShort(date: DateTime) {
        return date.setLocale('fr').toFormat('dd LLL');
    }

    public getParkingSpaceBookings(id: string) {
        return this.bookings.filter(e => e.id === id);
    }

    public getStyle(id: string) {
        const booking = this.bookings.find(e => e.id === id);

        if (typeof booking === 'undefined') {
            return '';
        }
        return booking.login === this.userLogin ? 'booked-by-user' : 'booked';
    }

    public isBooked(id: string) {
        return this.bookings.some(e => e.id === id);
    }

    public openBookingModal(id: string) {
        this.selectedParkingSpaceId = id;
        if (this.isSelectedParkingSpaceBookable) {
            if (this.conflictingBooking === null) {
                this.$bvModal.show('new-reservation-modal');
            } else {
                this.$bvModal.show('replace-reservation-modal');
            }
        }
    }

    public async rebook() {
        await this.unbook();
        await this.book();
    }

    private get isSelectedParkingSpaceBookable() {
        const bookingsForSelectedParkingSpace = this.bookings
            .filter(e => e.id === this.selectedParkingSpaceId);

        return bookingsForSelectedParkingSpace.length === 0;
    }

    private get isStartAndEndDateEqual() {
        return getDiffInDay(this.dateRange.start, this.dateRange.end) === 0;
    }

    private get userLogin() {
        return Bridge.user.login;
    }

    private async unbook() {
        if (this._duration === 'day' && this.isStartAndEndDateEqual) {
            const isSuccessful = await Bridge.unbookParkingSpace(this.dateRange.start, this.dateRange.end, this.userLogin ?? '');

            if (isSuccessful) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
            }
        } else {
            const unbookingPromises: Array<Promise<boolean>> = [];

            for (let d = this.dateRange.start; d <= this.dateRange.end; d = d.plus({ day: 1 })) {
                unbookingPromises.push(Bridge.unbookParkingSpace(d, d, this.userLogin ?? ''));
            }
            const isSuccessful = await Promise.all(unbookingPromises);

            if (isSuccessful.every(e => e === true)) {
                this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
            } else {
                this.showToast(this.$t('Failure') as string, this.$t('Booking_cancelled_faillure') as string)
            }
        }
        await this.setParkingSpaceBookings();
    }

    private async setParkingSpaceBookings() {
        const dateFilter: DateFilter = { beginDate: this.dateRange.start, endDate: this.dateRange.end };

        this.bookings = await Bridge.getParkingSpaceBookings(dateFilter, undefined, false);
    }

    private showToast(title: string, message: string) {
        this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000
        });
    }

    @Watch('_dateRange')
    async onDateRangeChange() {
        this.dateRange.start = DateTime.fromISO(this._dateRange.start).startOf('day');
        this.dateRange.end = DateTime.fromISO(this._dateRange.end).startOf('day');
        await this.setParkingSpaceBookings();
    }

    @Watch('_duration')
    async onDurationChange() {
        await this.setParkingSpaceBookings();
        this.bookings = this.bookings.filter(e => {
            if (this._duration === 'day') {
                return true;
            }
            return e.duration === this._duration || e.duration === 'day';
        });
    }
}
