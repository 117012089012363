
import { CalendarOptions, EventInput, EventSourceInput } from "@fullcalendar/core";
import luxonPlugin from "@fullcalendar/luxon3";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/vue";
import { DateTime } from "luxon";
import { ScheduleInformation } from "@microsoft/microsoft-graph-types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { getConferenceRooms, getDailySchedules } from "@/services/ConferenceRoom";
import { getMsalInstance } from "@/services/Msal";
import conferenceRoomJSON from '@/assets/configuration/conference_room.json'

@Component({
    components: {
        FullCalendar
    }
})
export default class ConferenceRoomSchedule extends Vue {
    @Prop() date!: string;
    @Prop() floor!: number;

    @Prop() isMobileView!: boolean;

    public rooms: Array<ConferenceRoom> = getConferenceRooms(this.floor);
    public hiddenRooms: Array<string> = [];
    public options: Array<CalendarOptions> = [];

    public conferenceRoomCoordinates = conferenceRoomJSON;

    private baseOptions: CalendarOptions = {
        eventMaxStack: 1,
        firstDay: 1,
        initialView: "timeGridDay",
        locale: "fr",
        plugins: [luxonPlugin, timeGridPlugin],
        slotMinTime: "08:00:00",
        slotMaxTime: "20:00:00",
        titleFormat: "LLLL d, yyyy",
        weekends: false,
        headerToolbar: false,
        allDaySlot: false
    };

    get isAuthenticated() {
        return getMsalInstance()?.isUserAuthenticated ?? false;
    }

    async mounted() {
        await this.setCalendarEvents();
    }

    public async login() {
        await getMsalInstance()?.login();
        await this.setCalendarEvents();
    }

    public formatDate(date: string) {
        return DateTime.fromISO(date)
            .setLocale("fr")
            .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    }

    public toggleRoomDisplay(room: ConferenceRoom): void {
        const roomAddess = room.address;
        const hiddenRoomIndex = this.hiddenRooms.indexOf(roomAddess);
        const roomIndex = this.rooms.findIndex(r => r.address === roomAddess);

        if (hiddenRoomIndex === -1) {
            this.rooms[roomIndex].hidden = true;
            this.hiddenRooms.push(roomAddess);
        } else {
            this.rooms[roomIndex].hidden = false;
            this.hiddenRooms.splice(hiddenRoomIndex, 1);
        }
        this.setCalendarEvents();
    }

    private getEventsFromSchedule(schedule: ScheduleInformation): EventSourceInput {
        if (schedule.scheduleItems === null || typeof schedule.scheduleItems === 'undefined') {
            return [];
        }
        return schedule.scheduleItems.map((item) => ({
            title: item.subject ?? '',
            start: item.start?.dateTime,
            end: item.end?.dateTime,
            room: item.location
        }));
    }

    private async setCalendarEvents() {
        const rooms = this.isMobileView ? getConferenceRooms() : getConferenceRooms(this.floor);
        const addresses = rooms.map(e => e.address).filter(e => !this.hiddenRooms.includes(e));
        const schedules: Array<ScheduleInformation> = addresses?.length ? await getDailySchedules(
            DateTime.fromISO(this.date).startOf('day'),
            this.rooms.map(e => e.address).filter(e => !this.hiddenRooms.includes(e))
        ) : [];

        this.rooms = rooms;
        if (this.isMobileView) {
            const allEvents: EventSourceInput = schedules.flatMap((schedule) => this.getEventsFromSchedule(schedule) as EventInput);

            this.options = [{
                ...this.baseOptions,
                eventMaxStack: undefined,
                initialDate: this.date,
                events: allEvents,
                eventColor: undefined,
                height: "calc((100vh - 18rem))",
            }];

            allEvents.forEach((event, index) => {
                const roomColor = this.rooms.find(room => {
                    const formattedRoom = this.trimString(event.room);
                    const separatorIndex = event.room.indexOf(';') - 1;

                    return formattedRoom.substring(separatorIndex).includes(this.trimString(room.name));
                });

                if (roomColor) {
                    (this.options[0].events as EventInput[])![index].color = roomColor.color;
                }
            });
        } else {
            this.options = schedules.map((schedule) => ({
                ...this.baseOptions,
                initialDate: this.date,
                events: this.getEventsFromSchedule(schedule),
                height:
                    schedules.length > 1
                        ? `calc((100vh - 25rem) / ${schedules.length})`
                        : "calc((100vh - 20rem))",
            }));
        }
    }

    private trimString(string: string) {
        return string.replace(/\s/g, '').toLowerCase()
    }

    @Watch("floor")
    async onFloorChange() {
        this.rooms = getConferenceRooms(this.floor);
        await this.setCalendarEvents();
    }

    @Watch("date")
    async onDateChange() {
        await this.setCalendarEvents();
    }
}
