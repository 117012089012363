import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'

import * as Bridge from '@/services/Bridge'
import Bookings from '@/views/Bookings.vue'
import Book from '@/views/Book.vue'
import Parking from '../views/Parking.vue'
import ConferenceRoomView from '@/views/ConferenceRoomView.vue'
import Login from '@/views/Login.vue'

Vue.use(VueRouter)

function guard(to: Route, from: Route, next: Function) {
    if (to.path == '/login') {
        next();
    }

    Bridge.isAuth().then(async res => {
        if (res.status == 200) {
            if (!Bridge.user.login) {
                await Bridge.loadUserDetails();
            }
            next();
        } else {
            next('/login');
        }
    }).catch(() => {
        if (!!Bridge.user.login) {
            Bridge.cleanUserDetails();
        }
        next('/login');
    });
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/book'
  },
  {
    path: '/book',
    name: 'Book',
    beforeEnter: guard,
    component: Book
  },
  {
    path: '/bookings',
    name: 'bookings',
    beforeEnter: guard,
    component: Bookings
  },
  {
    path: '/parking',
    name: 'parking',
    beforeEnter: guard,
    component: Parking
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/room',
    name: 'Conference Room',
    beforeEnter: guard,
    component: ConferenceRoomView
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router
