
import { VueConstructor } from "vue";
import { Component, Vue } from "vue-property-decorator";

import RoomIcon from '../assets/room-white.svg';
import ScreenIcon from '../assets/screen-white.svg';
import CalendarIcon from '../assets/calendar-white.svg';
import ParkingIcon from '../assets/icon_parking.svg';
import { Location } from "vue-router";

interface Tab {
    title: string;
    route: string | Location;
    icon: VueConstructor<Vue>;
}

@Component({
    components: {
        RoomIcon,
        ScreenIcon,
        CalendarIcon,
        ParkingIcon
    }
})

export default class MobileNavbar extends Vue {
    public tabs: Tab[] = [
        {
            title: 'new_booking',
            route: { path: '/book', query: { pan : null } },
            icon: ScreenIcon,
        },
        {
            title: 'Book_parking',
            route: { path: '/parking' },
            icon: ParkingIcon,
        },
        {
            title: 'room_booking',
            route: '/room',
            icon: RoomIcon,
        },
        {
            title: 'my_bookings',
            route: '/bookings',
            icon: CalendarIcon,
        }
    ]
}
