
import { DateTime } from "luxon";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConferenceRoomTimeSlotBookingForm from "@/components/ConferenceRoom/ConferenceRoomTimeSlotBookingForm.vue";
import ConferenceRoomTimeSlotPopover from "@/components/ConferenceRoom/ConferenceRoomTimeSlotPopover.vue";
import { getCurrentUserDisplayName } from "@/services/ConferenceRoom";

@Component({
    components: {
        ConferenceRoomTimeSlotBookingForm,
        ConferenceRoomTimeSlotPopover
    }
})
export default class ConferenceRoomTimeSlot extends Vue {
    @Prop() public availabilityView!: Array<string>;
    @Prop() public room!: ConferenceRoom;
    @Prop() public selectedDate!: string;

    private currentTime = DateTime.now();
    private slot = 0;
    private currentUser = '';
    private validatingSlots: {[date: string]: number[]} = {};

    async mounted() {
        this.currentUser = await getCurrentUserDisplayName()
    }

    private isBooked(slot: number): boolean {
        if (typeof slot !== 'undefined' && typeof this.availabilityView !== 'undefined') {
            return this.availabilityView[slot - 1] !== '';
        }
        return false;
    }

    public formatDateShort(date: string) {
        return DateTime.fromISO(date).setLocale('fr').toFormat("dd LLL yyyy");
    }

    public getStartingBookedTime(slot: number) {
        let startSlot = slot;
        const user = this.availabilityView[slot];

        if (user !== '') {
            for (let it = slot; it >= 0 && this.availabilityView[it] === user; it--) {
                startSlot -= 1;
            }
        }
        return startSlot + 2;
    }

    public getEndingBookedTime(slot: number) {
        let endSlot = slot;
        const user = this.availabilityView[slot];

        if (user !== '') {
            for (let it = slot; it < this.availabilityView.length && this.availabilityView[it] === user; it++) {
                endSlot += 1;
            }
        }
        return endSlot + 1;
    }

    public getTimeFromSlot(slot?: number) {
        const n = slot ?? this.slot;

        return DateTime.fromISO(this.selectedDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).plus({ minutes: 15 * (n - 1) });
    }

    public getTimeSlotStyle(slot: number) {
        const validating = !!this.validatingSlots[this.selectedDate]?.find(e => e === slot);

        if (this.isBooked(slot)) {
            return this.availabilityView[slot - 1] === this.currentUser ? 'my-booking' : 'booked'
        } else if (validating) {
            return 'validating';
        }

        if (this.currentTime > this.getTimeFromSlot(slot)) {
            return 'disabled';
        }
        return '';
    }

    public setValidatingSlots(slots: number[]) {
        const startingSlot = slots[0];
        const endingSlot = slots[1];

        if (!this.validatingSlots[this.selectedDate]) {
            this.validatingSlots[this.selectedDate] = [];
        }
        for (let i = startingSlot; i < endingSlot; i++) {
            this.validatingSlots[this.selectedDate].push(i);
        }
        this.$emit('confirmed');
    }

    public showModal(id: string, slot: number) {
        this.slot = slot;
        this.$bvModal.show(id);
    }
}
