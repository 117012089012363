
import { DateTime } from "luxon";
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component({})
export default class TimeSelector extends Vue {
    @Prop() public start!: DateTime;
    @Prop({default: false}) public error!: boolean;

    public hourSlots: Array<string> = [];
    public minuteSlots: Array<string> = [];

    private MIN_HOUR = 8;
    private MAX_SLOT = 20;
    private MIN_MINUTE = 0;
    private MAX_MINUTE = 45;
    private MINUTE_INTERVAL = 15;

    private selectedHour!: string;
    private selectedMinute!: string;
    private selectedTime!: string;

    public created() {
        this.setUpSlots();
        this.setTimeSlot();
        this.setSelectedTime();
    }
    
    @Emit('selected-hour')
    public selectTime() {
        return this.selectedHour
    }

    public setUpSlots() {
        for (let hour = this.MIN_HOUR; hour <= this.MAX_SLOT; hour++) {
            this.hourSlots.push(this.formatSlotToString(hour));
        }

        for (let minute = this.MIN_MINUTE; minute <= this.MAX_MINUTE; minute += this.MINUTE_INTERVAL) {
            this.minuteSlots.push(this.formatSlotToString(minute));
        }
    }

    public setTimeSlot() {
        this.selectedHour = this.formatSlotToString(this.start.hour);
        this.selectedMinute = this.formatSlotToString(this.start.minute);   
    }

    @Emit('selected-time')
    public setSelectedTime() {
        this.selectedTime = `${this.selectedHour}:${this.selectedMinute}:00`
        return this.selectedTime;
    }

    private formatSlotToString(slot: number) {
        return slot < 10 ? '0' + slot.toString() : slot.toString();
    }
}
