
import { DateTime } from 'luxon';
import { Component, Vue, PropSync, Emit } from "vue-property-decorator";

import CalendarIcon from '@/assets/calendar.svg';
import UserSearch from '@/components/UserSearch/UserSearch.vue';

@Component({
    components: {
        CalendarIcon,
        UserSearch
    }
})
export default class MapFilter extends Vue {
    @PropSync("dateRange") public _dateRange!: DateRangeString;
    @PropSync("duration") public _duration!: BookingDuration;
    @PropSync("hideUserSearch") public _hideUserSearch!: boolean;

    public modelConfig = { type: 'string' };
    public minDate = DateTime.now().startOf('day').toISO();
    public maxDate = DateTime.now().startOf('day').plus({ months: 2 }).toISO();
    public selectedDate = DateTime.now().startOf('day').toISO();

    public setNewDuration(duration: BookingDuration) {
        this._duration = duration;

        if (duration === 'day') {
            this._dateRange = {
                start: this.selectedDate,
                end: this.selectedDate,
            }
        } else {
            this.selectedDate = this._dateRange.start ?? this.selectedDate;
        }
    }

    public onSelectedDateChanged(val: string) {
        this._dateRange = {
            start: DateTime.fromISO(val).startOf('day').toISO() ?? DateTime.now().startOf('day').toISO(),
            end: DateTime.fromISO(val).startOf('day').toISO() ?? DateTime.now().startOf('day').toISO()
        }
    }

    @Emit('on-booking-selected')
    public onBookingSelected(booking: DeskBookingResponse) {
        return booking;
    }
}
